.workspaceContainer {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
}

.stage {
  /* background-color: var(--REB-white) !important; */
  /* -webkit-box-shadow: inset 0px 5px 4px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 5px 4px -1px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 5px 4px -1px rgba(0, 0, 0, 0.75); */
  box-shadow: inset rgba(0, 0, 0, 0.4) 0px 3px 8px;
}
.nav-item > a {
  color: var(--REB-white) !important;
}

.nav-item > a.nav-link.active {
  background-color: var(--REB-secondary) !important;
}

.btnDashLink {
  font-weight: 800;
}

.navbar,
.navbar-brand {
  padding: 0 !important;
  margin: 0 !important;
}
/* .navbar-toggler{
  back
} */
.navbar-toggler {
  height: 100%;
}
.navbar-collapse {
  height: 100%;
}
.navWorkspace {
  height: 100%;
}
.nav-workspace-links {
  height: 100%;
  background-color: var(--REB-primary) !important;
}
.workspace-links {
  height: 100%;
  line-height: 55px;
  padding: 0px !important;
}
.workspaceLogoCont {
  align-self: center;
  width: 100%;
  /* max-height: 55px !important; */
}
/* .navbar-nav {
  background-color: aliceblue !important;
} */

.workspace-nav {
  width: auto !important;
  height: 55px;
  background-color: var(--REB-primary) !important;
}

.navWorkspace {
  width: 100% !important;
  background-color: var(--REB-primary) !important;

  /* padding: 20px 0px 20px 0px !important; */
  /* -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75); */
}
.profileDropdown > .dropdown-toggle {
  height: 100% !important;
  background-color: var(--REB-secondary);
  border-radius: 0px;
}
.top-nav-cont {
  /* background: rgb(106, 106, 146); */
  height: 55px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(256, 256, 256, 0.7) 70%,
    var(--REB-primary) 100%
  );
}

.workspace-logo {
  max-width: 60%;
  max-height: 100%;
}
.btnLogout {
  background-color: var(--REB-warning) !important;
  /* margin-right: 40px; */
}
.btnProfile {
  background-color: transparent !important;
  text-decoration: underline;
  outline: none !important;
  -webkit-box-shadow: none !important;
}

.nav-pills .nav-link {
  border-radius: 0 !important;
}
.navbar-collapse {
  z-index: 1;
}

#dropdown-basic-button {
}
.bgGradient {
  background-image: -webkit-linear-gradient(
    60deg,
    rgba(61, 161, 255, 0.637),
    rgba(130, 220, 255, 0.904)
  );
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
}

@media (max-width: 991.98px) {
  .page-header-mobile-center {
    text-align: center;
  }
  .nav-hide-mobile {
    display: none !important;
  }
  .nav-show-mobile {
    display: block;
  }
  .profileDropdown {
    align-self: center;
  }
}
@media (min-width: 991.98px) {
  .page-header-mobile-end {
    text-align-last: end;
  }

  .nav-show-mobile {
    display: none;
  }
}

@media (max-width: 409px) {
  .workspace-logo {
    max-height: 35px !important;
  }
  .workspace-brand-cont {
    padding-left: 30px !important;
  }
  .stage {
    padding: 0px 0px 0px 0px;
  }
}

@media (min-width: 409px) and (max-width: 575.98px) {
  /* .workspace-logo {
    width: 45% !important;
  } */
  /* .workspace-brand-cont {
    padding-right: 40px !important;
  } */
  .workspace-logo {
    max-height: 35px !important;
  }
  .workspace-brand-cont {
    padding-left: 30px !important;
  }
  .stage {
    padding: 30px 0px 0px 0px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  /* .workspace-brand-cont {
    padding-left: 130px !important;
  } */
  .workspace-logo {
    max-height: 35px !important;
  }
  .workspace-brand-cont {
    padding-left: 30px !important;
  }
  /* .workspace-logo {
    width: 40% !important;
  } */
  .stage {
    padding: 30px 0px 0px 0px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .workspace-logo {
    max-height: 35px !important;
  }
  .workspace-brand-cont {
    padding-left: 30px !important;
  }
  .stage {
    padding: 30px 0px 0px 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .navWorkspace {
    padding-right: 15px !important;
  }
  .workspace-logo {
    max-height: 35px !important;
  }
  .top-nav-cont {
  }
  .workspace-links {
    font-size: 10px !important;
  }
  .btnProfile {
    font-size: 10px !important;
  }
  .btnLogout {
    font-size: 10px !important;
  }
  .stage {
    padding: 30px 40px 30px 40px;
  }
}

@media (min-width: 1200px) {
  .workspace-logo {
    max-height: 35px !important;
  }
  .navWorkspace {
    padding-right: 15px !important;
  }
  .top-nav-cont {
  }
  .stage {
    padding: 30px 40px 30px 40px;
  }
  /* .workspaceLogoCont {
    text-align: center;
  } */
}
@media (max-width: 1145px) {
  /* .workspace-logo {
    width: 80% !important;
  }
  .nav-workspace-links {
   
  }
  .workspace-links {
    font-size: 10px !important;
  }
  .btnProfile {
   
    font-size: 10px !important;
  }
  .btnLogout {
    font-size: 10px !important;
  } */
}
