.col-login {
  flex-direction: column;
  display: flex;
  /* background-color: var(--REB-white) !important;
  -webkit-box-shadow: -5px 0px 10px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -5px 0px 10px -4px rgba(0, 0, 0, 0.5); */
}

.btnLogin {
  /* background-color: var(--REB-primary) !important;
  -webkit-box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.5); */
}
.IlluContainer {
  background: rgb(9, 9, 121);
  background: linear-gradient(
    90deg,
    rgba(9, 9, 121, 0) 20%,
    var(--REB-primary) 100%
  );
  width: 100%;
  height: 100vh;
  position: absolute;
}

@media (max-width: 991px) {
  .landingContainer {
    height: 0 !important;
    width: 0 !important;
  }
  .form-login-welcome {
    display: none;
  }
}
@media (min-width: 992px) {
  .landing-logo {
    display: none;
  }
}
