@media (max-width: 992px) {
  .verification-modal-header-pdf-col {
    margin-top: 10px;
  }
  .verification-modal-footer-buttons {
    margin-top: 10px;
  }
}

@media (min-width: 409px) and (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) {
  .verification-modal-body {
    padding-left: 90px;
    padding-right: 90px;
  }
}
@media (max-width: 3000px) {
}
