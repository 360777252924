/* @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap"); */
@import "~react-image-gallery/styles/css/image-gallery.css";

/* #SURFACEPLUS {
  --REB-white: #fcfeff;
  --REB-bg-color: #f5f7fc;
  
  --REB-primary: #007bd3;
  --REB-secondary: #5eaaff;
  --REB-tertiary: #0050a1;
  --REB-black: #0d0d0d;
  --REB-body: #adb4c6;
  --REB-green: #4f6228;
  --REB-grey: #0d0d0d;
  --REB-warning: #e24a4a;
  --REB-yellow: #f5d941;
  --REB-info: #c4dad5;
  --REB-blue: #0f4c75;
}




/* .col,
.row {
  padding: 0 !important;
  margin: 0 !important;
} */

.btn-wrap-text {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.btn {
  border: none !important;
}


.globalContainerStyle {
  border-radius: 10px !important;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  background-color: var(--REB-white) !important;
}

.form-control,
.form-label {
  font-family: "Nunito", sans-serif !important;
}

input,
button,
textarea,
.btn-group,
.input-group-prepend,
.input-group-text,
select {
  /* border-radius: 0px !important; */
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: "Nunito", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.required-field {
  color: var(--REB-warning) !important;
}

.modal-body {
  background-color: var(--REB-white) !important;
}
.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.ReactTable .-pagination {
  box-shadow: none !important;
}

#select-all {
  display: none;
}

.modal-footer {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.modal-dialog {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  /* height: 100%; */
  /* max-height: 100vh !important; */
}

.global-modal-header {
  background-color: var(--REB-primary) !important;
}
.global-modal-header-title {
  color: var(--REB-white) !important;
}
.global-modal-header-title > h6 {
  font-size: 20px !important;
  font-weight: bold !important;
}
.global-modal-body > h6 {
  font-size: 18px !important;
  font-weight: bold !important;
}
.global-modal-body {
  background-color: var(--REB-white) !important;
}
.pdf-modal-dialog {
  max-width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
  /* max-height: 100vh !important; */
}
.pdf-modal {
  padding: 0px !important;
  margin: 0px !important;
  /* max-height: 100vh !important; */
}
.pdfViewer {
  width: 100% !important;

  height: 85vh !important;
}

.modalChildBackground {
  background-color: rgba(0, 0, 0, 0.75);
}

.navbar-toggler,
.navbar-light {
  /* color: white !important; */
  /* border-color: white !important; */
  /* background-color: var(--REB-secondary) !important; */
  border-color: transparent !important;
  color: "black";
}
