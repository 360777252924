.actor-btn-crud-col {
  /* align-content: flex-end !important; */
  /* padding-left: 30% !important; */
}
.actor-btn-crud {
  /* background-color: var(--REB-primary) !important; */
  /* margin: 0px 10px 0px 10px !important; */
}
.actor-btn-crud-inner-cont {
  /* align-self: flex-end !important; */
}
.actor-modal-footer {
  border-top: var(--REB-black) solid 1px;
}
.actor-modal-footer-row {
  padding-top: 20px !important;
  width: 100% !important;
}
.actor-modal-header {
  background-color: var(--REB-primary) !important;
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.actor-modal-body {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.actor-modal-header-col {
  color: var(--REB-white) !important;
}
.btnDeleteActor {
  background-color: var(--REB-warning) !important;
}
.user-select {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.actor-permission-crud {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.btnAddActor {
  background-color: var(--REB-primary) !important;
}
.btnEditActor {
  background-color: var(--REB-secondary) !important;
}
.actorBtnViewRow {
  background-color: var(--REB-primary) !important;
}

.actor-date-date {
  color: var(--REB-primary) !important;
}

.actor-row-crud {
  padding: 10px 0px 10px 0px !important;
}

.actor-ico-search {
  background-color: #fff !important;
  /* border-right: transparent !important; */
}
.actor-searchTable {
  border-left: transparent !important;
}
.form-control:focus {
  outline-color: #ced4da !important;
  border-color: #ced4da !important;
}
.actor-modal-header {
  width: 100% !important;
}
.modal-actor-100w {
  max-width: 100% !important;
  padding: 0px !important;
}

.css-actorInput {
  /* padding-right: 60px !important; */
}

.user-col-cont-padding {
  padding-left: 30px !important;
  /* padding: 0px 30px 0px 30px !important; */
}
.cont-pw {
  background-color: rgb(248, 248, 248);
}
.actor-modal-header-row {
  border-bottom: var(--REB-black) solid 1px;
}
.actor-modal-header-text {
  font-weight: 600 !important;
  color: var(--REB-primary) !important;
}

.lblPasswordMsg {
  color: var(--REB-warning);
  line-height: 17px;
}
.actor-btn-crud > * {
  pointer-events: none !important;
}
/* .tableActor > .pagination-bottom > .-pagination > .previous {
  background-color: aquamarine !important;
} */

@media (max-width: 409px) {
  .tableActor > .pagination-bottom > .-pagination > .-previous > button,
  .tableActor > .pagination-bottom > .-pagination > .-next > button {
    /* padding: 1px; */
    /* width: 50%; */
    background-color: var(--REB-tertiary) !important;
    color: var(--REB-white) !important;
    font-weight: bold;
    font-size: 0.5em;
  }
  .tableActor > .pagination-bottom > .-pagination > .-center > .-pageInfo {
    font-size: 0.5em !important;
  }
}
@media (min-width: 409px) {
  .tableActor > .pagination-bottom > .-pagination > .-previous > button,
  .tableActor > .pagination-bottom > .-pagination > .-next > button {
    padding: 1px;
    height: 30px;
    width: 100px;
    background-color: var(--REB-tertiary) !important;
    color: var(--REB-white) !important;
    font-weight: bold;
  }
  .tableActor > .pagination-bottom > .-pagination > .-next {
    text-align: -webkit-left;
    padding-right: 20px;
    align-self: center;
  }
  .tableActor > .pagination-bottom > .-pagination > .-previous {
    text-align: -webkit-right;
    padding-left: 20px;
    align-self: center;
  }
}

/* .dropdown:hover > .dropdown-menu {
  display: block;
}
.dropdown > .dropdown-toggle:active {
  pointer-events: none;
} */

/* Mike */

.rt-td {
  align-self: center;
}
