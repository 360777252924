.itemModalGallery {
  width: 220px;
  height: 220px;
  text-align: "webkit-center";
}

.image-gallery-slide img {
  width: 100%;
  height: 220px;
}
.btnCalc {
  border: 1px solid var(--REB-green) !important;
}
